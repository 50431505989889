import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import devices from '../../../../../js/styles/Devices';
import intlShape from '../../../../../js/schemas/intl';
import tooltipSchema from '../../../../../js/schemas/tooltip';
import tooltipToObject from '../../../../../js/helpers/tooltipToObject';
import Tooltips from '../../../../utils/tooltips';
import SalarySuggestion from '../../../../utils/SalarySuggestion';

const Wrapper = styled.div`
  && {
    margin: ${(props) => props.margin || '0 0 1rem 0'};
    position: relative;

    @media ${devices.upFromTablet} {
      width: ${(props) => (props.wrapperWidth ? '100%' : '14rem')};
    }

    @media ${devices.upFromSmallDesktop} {
      width: ${(props) => (props.wrapperWidth ? '100%' : '15.125rem')};
    }
  }
`;

const EngSuffix = styled.div`
  && {
    margin-left: 0.5em;
    font-weight: bold;
    color: var(--black);
  }
`;

const Field = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    border: 2px solid var(--white);
    border-radius: 0.25rem;
    cursor: text;
    overflow: hidden;
    box-sizing: border-box;
    height: 3rem;

    ${(props) => props.valid && props.validated && css`
      border-color: var(--teal);
    `}

    ${(props) => (props.validated && props.valid === false) && css`
      border-color: var(--red);
    `}

    ${(props) => props.valid === null && css`
      border-color: var(--white);
    `}

    ${(props) => (props.formType === 'loanCalculator') && css`
      border: none !important;
    `}
  }
`;

const LabelWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    padding: 0 0 0.5rem;
  }
`;

const Label = styled.label`
  && {
    position: relative;
    z-index: 1;
  }
`;

const InputWrapper = styled.div`
  && {
    width: 100%;
  }
`;

const Input = styled.input`
  && {
    border: none;
    outline: none;
    align-items: center;
    width: 100%;
    background: var(--white);
    padding: 0.6em;
    font-smooth: antialiased; // Non-standard: This feature is non-standard and is not on a standards track.
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
  }
`;

const TextInput = ({
  intl: { formatMessage },
  id,
  name,
  compact,
  labelOutside,
  label,
  placeholder,
  suffix,
  removeSuffix,
  validated,
  valid,
  className,
  margin,
  tooltip,
  tooltipLink,
  whitelist,
  disableButton,
  submitSuccess,
  expandedForm,
  setShowErrors,
  passedRef,
  changeField,
  descriptionText,
  inputRef,
  contactForm,
  isNumber,
  englishSuffix,
  lead,
  fullWidth,
  formType,
  ...rest
}) => {
  // eslint-disable-next-line
  const inputId = id || `text-input--${name || ''}--${label.replace(/\s/, '-')}`;
  const WrapperWidth = formType === 'lead' || formType === 'loanCalculator';

  const WebsiteLink = (chunks) => <a href={tooltipLink} target="_blank" rel="noreferrer">{chunks}</a>;

  const tooltipObject = tooltipToObject(tooltip);
  if (typeof tooltip === 'object') {
    tooltipObject.text = formatMessage(tooltip.text, {
      a: WebsiteLink,
    });
  }
  const fieldNames = [
    'applicant.otherMonthlyIncomeBeforeTax',
    'applicant.spouseMonthlyIncomeBeforeTax',
    'applicant.monthlyIncomeBeforeTax',
    'applicant.monthlyIncomeAfterTax',
    'coApplicant.monthlyIncomeBeforeTax',
    'coApplicant.monthlyIncomeAfterTax',
    'coApplicant.otherMonthlyIncomeBeforeTax',
  ];

  return (
    <Wrapper
      wrapperWidth={WrapperWidth || fullWidth}
      margin={margin}
    >
      {label && (contactForm || label.id) && (
      <LabelWrapper>
        <Label
          htmlFor={inputId}
        >
          {contactForm ? label : formatMessage(label)}
        </Label>
        { tooltipObject.text
          && (
            <Tooltips tooltipObject={tooltipObject} />
          )}
      </LabelWrapper>
      )}
      <Field
        validated={validated}
        valid={valid}
        formType={formType}
        lead={lead}
      >
        {englishSuffix && (
        <EngSuffix>
          <span translate="no">{formatMessage(suffix)}</span>
        </EngSuffix>
        )}
        {(placeholder || contactForm) && (placeholder.id || contactForm) && (
          <InputWrapper>
            <Input
              ref={inputRef}
              id={inputId}
              name={name}
              placeholder={contactForm ? placeholder : formatMessage(placeholder)}
              data-hj-whitelist={whitelist || null}
              tabIndex="0"
              {...rest}
            />
          </InputWrapper>
        )}
        {(validated && suffix) && !removeSuffix && !englishSuffix && (
        <div className={`${formType === 'loanCalculator' ? 'c-field__suffix-calculator' : 'c-field__suffix'}`}>
          <span translate="no">{formatMessage(suffix)}</span>
        </div>
        )}
      </Field>
      {fieldNames.map((fieldName) => {
        if (String(name) === fieldName) {
          return <SalarySuggestion fieldName={name} key={name} />;
        }
        return null;
      })}
    </Wrapper>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  intl: intlShape.isRequired,
  type: PropTypes.oneOf(['text', 'tel', 'email', 'password', 'url', 'search']),
  name: PropTypes.string,
  compact: PropTypes.bool,
  labelOutside: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
    description: PropTypes.string,
  }),
  suffix: PropTypes.shape(),
  englishSuffix: PropTypes.bool,
  removeSuffix: PropTypes.bool,
  validated: PropTypes.bool,
  valid: PropTypes.bool,
  className: PropTypes.string,
  tooltip: tooltipSchema,
  tooltipLink: PropTypes.string,
  override: PropTypes.shape({
    input: PropTypes.shape({
      className: PropTypes.string,
      labelClassName: PropTypes.string,
    }),
  }),
  whitelist: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.func,
  ]),
  contactForm: PropTypes.bool,
  placeholder: PropTypes.shape(),
  margin: PropTypes.string,
  isNumber: PropTypes.bool,
  disableButton: PropTypes.bool,
  submitSuccess: PropTypes.bool,
  expandedForm: PropTypes.bool,
  setShowErrors: PropTypes.func,
  passedRef: PropTypes.shape(),
  changeField: PropTypes.func,
  descriptionText: PropTypes.string,
  lead: PropTypes.bool,
  formType: PropTypes.string,
  fullWidth: PropTypes.bool,
};

TextInput.defaultProps = {
  id: null,
  type: 'text',
  name: null,
  compact: false,
  labelOutside: false,
  label: {},
  suffix: '',
  englishSuffix: false,
  removeSuffix: false,
  validated: false,
  valid: null,
  className: '',
  tooltip: '',
  tooltipLink: '',
  lead: false,
  formType: '',
  override: {
    input: {},
  },
  whitelist: false,
  inputRef: null,
  contactForm: false,
  placeholder: {},
  margin: '',
  isNumber: false,
  disableButton: false,
  submitSuccess: false,
  expandedForm: false,
  setShowErrors: () => {},
  passedRef: {},
  changeField: () => {},
  descriptionText: '',
  fullWidth: false,
};

export default injectIntl(TextInput);

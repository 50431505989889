import { useEffect, useState } from 'react';
import {
  useDispatch,
} from 'react-redux';
import { change } from 'redux-form';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';
import { useGetApplicationQuery } from '../../store/reducers/api-call';

const IdKollenLogin = () => {
  const dispatch = useDispatch();
  const changeField = (...args) => change('application', ...args);
  const [token, setToken] = useState();
  const [filled, setFilled] = useState();

  const getUrlToken = (urlQueryString) => {
    const queryObject = queryString.parse(urlQueryString);
    return queryObject.t || null;
  };

  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    if (window.location.pathname === '/reapply/') {
      setToken(getUrlToken(window.location.search));
    }
  }, [setToken]);

  const {
    data,
  } = useGetApplicationQuery({ t: token, src: 'reapply' });

  if (data !== undefined && filled !== true) {
    dispatch(changeField('totalAmount', data?.totalAmount));
    dispatch(changeField('repaymentTime', data?.repaymentTime));
    dispatch(changeField('acceptAgreement', data?.acceptAgreement));
    dispatch(changeField('creditCheckConsent', data?.creditCheckConsent));
    dispatch(changeField('merge', data?.merge));
    dispatch(changeField('bankAccount', data?.bankAccount));
    dispatch(changeField('purpose', data.purpose ? data.purpose : ''));
    dispatch(changeField('applicant.dependants', data?.applicant?.dependants));
    dispatch(changeField('applicant.employmentSince', data.applicant.employmentSince ? data.applicant?.employmentSince : ''));
    dispatch(changeField('applicant.employmentSinceMonth', data.applicant.employmentSince ? data.applicant.employmentSince : ''));
    dispatch(changeField('applicant.email', data?.applicant?.email));
    dispatch(changeField('applicant.phoneNumber', data?.applicant?.phoneNumber));
    dispatch(changeField('applicant.homeType', data?.applicant?.homeType));
    dispatch(changeField('applicant.nationalId', data?.applicant?.nationalId));
    dispatch(changeField('applicant.civilStatus', data?.applicant?.civilStatus));
    dispatch(changeField('applicant.homeCost', data.applicant.homeCost ? data.applicant.homeCost : ''));
    dispatch(changeField('applicant.employmentType', data.applicant.employmentType ? data.applicant?.employmentType : ''));
    dispatch(changeField('applicant.monthlyIncomeBeforeTax', data.applicant.monthlyIncomeBeforeTax ? data.applicant.monthlyIncomeBeforeTax : ''));
    dispatch(changeField('applicant.monthlyIncomeAfterTax', data.applicant.monthlyIncomeAfterTax ? data.applicant.monthlyIncomeAfterTax : ''));
    dispatch(changeField('applicant.employerName', data.applicant.employerName ? data.applicant.employerName : ''));
    dispatch(changeField('applicant.employerPhone', data.applicant.employerPhone ? data.applicant?.employerPhone : ''));
    dispatch(changeField('applicant.citizenship', data?.applicant?.citizenship.toLowerCase()));
    dispatch(changeField('applicant.politicallyExposed', data?.applicant?.politicallyExposed));
    dispatch(changeField('applicant.monthlyNetSalary', data.applicant.monthlyNetSalary ? data.applicant.monthlyNetSalary : ''));
    dispatch(changeField('applicant.otherMonthlyIncomeBeforeTax', data.applicant.otherMonthlyIncomeBeforeTax ? data.applicant.otherMonthlyIncomeBeforeTax : ''));
    dispatch(changeField('applicant.homeTypeCategory', data.applicant.homeTypeCategory ? data.applicant.homeTypeCategory : ''));
    dispatch(changeField('applicant.otherLivingCost', data.applicant.otherLivingCost ? data.applicant.otherLivingCost : ''));
    dispatch(changeField('applicant.spouseMonthlySalary', data.applicant.spouseMonthlySalary ? data.applicant.spouseMonthlySalary : ''));
    dispatch(changeField('applicant.employmentType', data.applicant.employmentType ? data.applicant.employmentType : ''));
    dispatch(changeField('applicant.employerName', data.applicant.employerName ? data.applicant.employerName : ''));
    dispatch(changeField('applicant.occupationCategory', data.applicant.occupationCategory ? data.applicant.occupationCategory : ''));
    dispatch(changeField('applicant.occupationTitle', data.applicant.occupationTitle ? data.applicant.occupationTitle : ''));
    dispatch(changeField('applicant.educationLevel', data.applicant.educationLevel ? data.applicant.educationLevel : ''));
    dispatch(changeField('applicant.moveInDate', data.applicant.moveInDate ? data.applicant.moveInDate : ''));
    dispatch(changeField('applicant.hasCreditCard', data?.applicant?.hasCreditCard));
    dispatch(changeField('applicant.employmentTo', data.applicant.employmentTo ? data.applicant.employmentTo : ''));
    dispatch(changeField('applicant.employmentToMonth', data.applicant.employmentToMonth ? data.applicant.employmentToMonth : ''));
    dispatch(changeField('applicant.spouseMonthlyIncomeBeforeTax', data.applicant.spouseMonthlyIncomeBeforeTax ? data.applicant.spouseMonthlyIncomeBeforeTax : ''));
    dispatch(changeField('applicant.militaryServiceStatus', data.applicant.militaryServiceStatus === null ? 'notAMilitaryServant' : data.applicant.militaryServiceStatus.toLowerCase()));
    setFilled(true);
  }
  //  else if (window.location.pathname === '/reaply/' && !token) {
  //   dispatch(changeField('applicant', { ...currentApplicant, nationalId: '' }));
  // }
};

IdKollenLogin.propTypes = {
};

export default injectIntl(IdKollenLogin);
